import styled, { keyframes, css } from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'

type ContainerProps = {
  isModal?: () => void;
}

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`
export const Container = styled.div<ContainerProps>`
  background: ${orange.extra};

  @media (min-width: ${breakpoints.md}) {
    background: none;
  }

  ${(props: ContainerProps) => props.isModal && (
    css`
      min-height: 100vh;
      padding: 30px 24px;;
      position: fixed;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      transition: 0.5s ease-in-out;
      animation: ${animatedModal} 0.5s ease-in-out forwards;

      @media (min-width: ${breakpoints.md}) {
        max-width: 596px;
        padding: 40px 100px;
      }
    `
  )}

  .form--default {
    label {
      color: ${white};
      margin-bottom: 4px;
    }

    input {
      padding: 8px;
      border-radius: 8px;

      @media (min-width: ${breakpoints.md}) {
        padding: 13px;
      }
    }

    a, button {
      max-width: 100%;
    }

    .form-label-check {
      &::before {
        top: 34px;

        @media (min-width: ${breakpoints.lg}) {
          top: 16px;
        }
      }

      a {
        color: ${white};
      }
    }

    .form-input-check {
      &:checked ~ label {
        &::before {
        background: transparent;
        border: 1px solid ${white};
      }
      &::after {
        top: 37px;
        
          @media (min-width: ${breakpoints.lg}) {
            top: 19px;
          }
        }
      }
    }
    .radio-contato {

      input:checked + .radio-check {
        border-color: ${white};

        &::after {
          content: '';
          background: ${white};
        }
      }
    }  
  }

  &.error, &.sent {
    background: ${white};
    width: 302px;
    min-height: 270px;
    box-shadow: 0 16px 32px 2px #16161614;
    padding: 32px 33.5px;

    @media (min-width: ${breakpoints.md}) {
      width: 640px;
      min-height: 308px;
      padding-left: 80px;
      padding-right: 80px;
    }
    @media (min-width: ${breakpoints.lg}) {
      min-height: 425px;
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;

  &:focus{
    outline: none;
  }
`
