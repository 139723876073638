import React from 'react'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import successImg from '../../../../../assets/images/form-success-image.png'

import { WIDTH_MD } from 'src/styles/breakpoints'

import { Container, CloseButton } from '../style'

type FormPlanilhaProps = {
  closeModal?: () => void | false;
  dataLayer: IDataLayerParams;
  setError: Function;
  setSent: Function;
  setLoading: Function;
}

const SentFormModal = ({ closeModal, dataLayer, setError, setSent, setLoading }: FormPlanilhaProps) => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  const closeButton = closeModal && (
    <CloseButton
      className='border-0 bg-transparent pt-4'
      onClick={() => {
        closeModal()
        setError(false)
        setSent(false)
        setLoading(false)
      }}
    >
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  return (
    <Container isModal={closeModal} className='d-flex align-items-center text-center sent'>
      {closeButton}
      <div className='col-12 px-0'>
        <img src={successImg} alt='Imagem de erro' width={width < WIDTH_MD ? 32 : 48} height={width < WIDTH_MD ? 32 : 48} />
        <h3 className='fs-16 lh-20 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 text-grayscale--500 my-4 fw-600'>
          <span className='d-md-block'>Parabéns, seu cadastro foi</span> realizado com sucesso!
        </h3>
        <hr />
        <div className='col-md-9 mx-auto'>
          <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--500 mb-4'>
            Mas caso não possa realizar o download agora, fique tranquilo! Em pouco tempo você
            receberá a planilha na sua caixa de e-mail.
          </p>
        </div>
        <a
          href='https://marketing.bancointer.com.br/empresas/documentos/planilha-DRE-inter-empresas.xlsx'
          className='fs-16 fw-600 text-white text-none btn btn--lg bg-orange--extra rounded-2 mx-auto mt-3'
          onClick={() => {
            sendDatalayerEvent({
              section: `m_${dataLayer.section}`,
              element_action: 'click button',
              element_name: 'Fazer download',
              element_previous: dataLayer.element_previous,
              section_name: dataLayer.section_name,
            })
          }}
        >
          Fazer download
        </a>
      </div>
    </Container>
  )
}

export default SentFormModal
