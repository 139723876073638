import React, { MouseEvent } from 'react'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import errorImg from '../../../../../assets/images/form-error-image.png'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import { Container, CloseButton } from '../style'

type FormPlanilhaProps = {
  closeModal?: () => void | false;
  dataLayer: IDataLayerParams;
  setError: Function;
  setSent: Function;
  setLoading: Function;
}

const ErrorFormModal = ({ closeModal, dataLayer, setError, setSent, setLoading }: FormPlanilhaProps) => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
  }

  const closeButton = closeModal && (
    <CloseButton
      className='border-0 bg-transparent pt-4'
      onClick={() => {
        closeModal()
        setError(false)
      }}
    >
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  return (
    <Container isModal={closeModal} className='d-flex align-items-center error'>
      {closeButton}
      <div className='col-12 text-center'>
        <img src={errorImg} alt='Imagem de erro' width={width < WIDTH_MD ? 32 : 48} height={width < WIDTH_MD ? 32 : 48} />
        <h3 className='font-sora fs-16 lh-20 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 my-2 text-grayscale--500'>Ocorreu um erro</h3>
        <p className='text-grayscale--400 fs-14 lh-17 fs-md-16 lh-md-17 mb-1'>
          <span className='d-block'>Não foi possível efetuar o cadastro.</span> Tente novamente mais tarde.
        </p>
        <button
          title='Entendi'
          className='btn btn--lg bg-orange--extra fw-600 text-white text-none rounded-2 mx-auto mt-3'
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            handleReturn(e)
            sendDatalayerEvent({
              section: `m_${dataLayer.section}`,
              element_action: 'click button',
              element_name: 'Entendi',
              element_previous: dataLayer.element_previous,
              section_name: dataLayer.section_name,
            })
          }}
        >
          Entendi
        </button>
      </div>
    </Container>
  )
}

export default ErrorFormModal
