import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import SectionComponent from 'src/components/SectionComponent'
import OrangeIcon from 'src/components/UI/MarkdownIcon/InterCoIcons'
import ScrollTo from 'src/components/ScrollTo'

import GerencieAsFinancas from './../../assets/data/gerencie-as-financas-do-seu-negocio.json'

import { Button } from './styles'

type DataProps = {
  icon: string;
  description: string;
}

const GerenciarFinancas = () => {
  return (
    <SectionComponent
      id='planilha-dre'
      SectionStyles='bg-orange--extra-light'
      minHeight={{ sm: '819px', md: '528px', lg: '669px', xl: '698px', xxl: '749px' }}
    >
      <div className='col-12 col-md-6 text-center text-md-left'>
        <ImageWebp
          pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dre-empresaria/image.webp'
          arrayNumbers={[ 312, 348, 448, 527, 527 ]}
          arrayNumbersHeight={[ 312, 348, 448, 527, 527 ]}
          altDescription='Empreendedora consultando sua planilha DRE Inter Empresas através de um tablet.'
        />
      </div>
      <div className='col-12 col-md-6'>
        <h2 className='font-sora text-grayscale--500 fs-20 lh-25 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-left mt-4 mt-md-0'>
          Gerenciar as finanças do seu negócio pode ser mais simples!
        </h2>
        <p className='text-grayscale--500 fs-14 lh-17 fs-lg-16 lh-lg-20 text-left'>
          Descubra com a planilha DRE se sua empresa gera lucro suficiente para cobrir custos, impostos e despesas.
        </p>
        {
          GerencieAsFinancas.map((item: DataProps) => (
            <div className='d-flex align-items-center mb-3' key={item.icon}>
              <div className='col-2 col-lg-1 px-md-0'>
                <OrangeIcon size='LG' color='#ff7a00' icon={item.icon} />
              </div>
              <div className='col-10 col-lg-11 pl-0 pl-lg-2 pl-xl-1'>
                <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-grayscale--500 mb-0'>{item.description}</p>
              </div>
            </div>
          ))
        }
        <ScrollTo
          to='#planilha-dre'
          section='dobra_02'
          sectionName='Gerenciar as finanças do seu negócio pode ser mais simples!'
          elementName='Baixar Agora'
          title='Baixar eBook gratuito'
        >
          <Button
            className='btn btn--lg text-white bg-orange--extra text-none mt-4'
          >
            Baixar Agora
          </Button>
        </ScrollTo>
      </div>
    </SectionComponent>
  )
}

export default GerenciarFinancas
