import React, { useState } from 'react'
import ImageWebp from 'src/components/ImageWebp'
import SectionComponent from 'src/components/SectionComponent'
import FormPlanilhaDRE from 'src/components/UI/Forms/FormPlanilhaDRE'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import usePageQuery from '../../pageQuery'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_LG, WIDTH_MD, WIDTH_XXXL } from 'src/styles/breakpoints'
import { BgFormPlanilha } from './styles'

const HeroPlanilhaDRE = () => {
  const data = usePageQuery()
  const width = useWidth()

  const [ dataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_02',
    element_action: 'click button',
    section_name: 'Cartão Inter Mastercard na Arena MRV',
    element_name: 'Abrir conta',
  })

  return (
    <SectionComponent
      id='planilha-dre'
      pageDataImages={data}
      bgMobile='bgHeroPlanilhaDreMobile'
      bgTablet='backgroundHeroPlanilhaDreTablet'
      bgDesktopLG='backgroundHeroPlanilhaDreDesktopLG'
      bgDesktopXL='backgroundHeroPlanilhaDreDesktopXL'
      bgDesktopXXL='backgroundHeroPlanilhaDreDesktopXL'
      SectionStyles='pb-0 py-md-0 bg-white'
      ContainerStyles='px-0'
      backgroundPosition={(width < WIDTH_LG && 'bottom center') || (width >= WIDTH_LG && width <= WIDTH_XXXL) ? 'right center' : 'right 190px center' }
      backgroundSize='initial'
      backgroundRepeat={width < WIDTH_MD ? 'repeat-x' : 'no-repeat'}
      minHeight={{ sm: '937px', md: '773px', lg: '669px', xl: '749px', xxl: '749px' }}
      role='img'
      ariaLabel='Imagem da capa da planilha DRE'
    >
      <div className='col-12 col-md-6 col-lg-4 text-center text-md-left px-5 px-md-3'>
        <ImageWebp
          pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/planiha-dre-hero/image.webp'
          arrayNumbers={[ 282, 304, 348, 422 ]}
          arrayNumbersHeight={[ 198, 279, 302, 323 ]}
          altDescription='Imagem da capa da planilha DRE'
        />
        <h1 className='font-sora text-orange--extra fs-20 lh-25 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-left pt-4'>
          <span className='d-block'>Planilha de DRE – </span>modelo gratuito
        </h1>
        <p className='text-grayscale--500 fs-14 lh-17 fs-md-18 lh-md-22 text-left mb-4'>
          Baixe grátis a planilha de DRE em Excel e acompanhe o desempenho financeiro da sua empresa. Modelo completo para
          facilitar a gestão.
        </p>
      </div>
      <BgFormPlanilha className='col-12 col-md-6 col-xl-5 bg-orange--extra px-5 px-md-4'>
        <FormPlanilhaDRE dataLayer={dataLayer} />
      </BgFormPlanilha>
    </SectionComponent>
  )
}

export default HeroPlanilhaDRE
