import React from 'react'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import HeroPlanilhaDRE from './sections/hero-planilha-dre/_index'
import GerenciarFinancas from './sections/gerenciar-financas/_index'
import PorqueAbrirUmaConta from './sections/porque-abrir-uma-conta/_index'

const PlanilhaDRE = () => {
  return (
    <Layout pageContext={pageContext}>
      <HeroPlanilhaDRE />
      <GerenciarFinancas />
      <PorqueAbrirUmaConta />
    </Layout>
  )
}

export default PlanilhaDRE
