import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const BgFormPlanilha = styled.div`

  @media ${device.tablet} {
    background: none;
    min-height: auto;
  }
`
