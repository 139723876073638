import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import SectionComponent from 'src/components/SectionComponent'
import OrangeIcon from 'src/components/UI/MarkdownIcon/InterCoIcons'

import GerencieAsFinancas from './../../assets/data/porque-abrir-uma-conta.json'

import { ButtonLink } from './styles'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type DataProps = {
  icon: string;
  description: string;
}

const PorqueAbrirUmaConta = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://conta-digital-pj.inter.co/login?utm_source=ebook&utm_medium=botao&utm_campaign=aquisicao_ebook_pj_botao&utm_term=conta-digital-pj&utm_content=planilha_dre'

  return (
    <SectionComponent
      id='planilha-dre'
      minHeight={{ sm: '875.41px', md: '562px', lg: '605px', xl: '738px', xxl: '738px' }}
    >
      <div className='col-12 col-md-6 col-lg-7 text-center text-md-left order-md-last'>
        <ImageWebp
          pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dre-app/image.webp'
          arrayNumbers={[ 310, 288, 488, 499, 499 ]}
          arrayNumbersHeight={[ 362, 315.24, 526, 558, 558 ]}
          altDescription='Cartão PJ Inter Empresas ao lado de um celular, que mostra a home do app Inter Empresas'
          className='float-xl-right'
        />
      </div>
      <div className='col-12 col-md-6 col-lg-5 pr-lg-0'>
        <h2 className='font-sora text-orange--extra fs-20 lh-25 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-left mt-4 mt-md-0'>
          Por que abrir uma conta Inter Empresas?
        </h2>
        <h3 className='text-grayscale--500 fs-14 lh-17 fs-lg-16 lh-lg-20 text-left fw-400'>
          Só com a conta Inter Empresas você aproveita soluções pensadas para simplificar o seu negócio
        </h3>
        {
          GerencieAsFinancas.map((item: DataProps) => (
            <div className='d-flex align-items-center mb-3' key={item.icon}>
              <div className='col-2 col-lg-1 px-md-0'>
                <OrangeIcon size='MD' color='#ff7a00' icon={item.icon} />
              </div>
              <div className='col-10 col-lg-11 pl-0 pl-lg-2 pl-xl-1'>
                <h3 className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-grayscale--500 mb-0 fw-400'>{item.description}</h3>
              </div>
            </div>
          ))
        }
        <ButtonLink
          href={url}
          title='Abrir Conta MEI gratuita'
          target='_blank'
          className='btn btn--lg text-white bg-orange--extra text-none mt-4'
          onClick={() => {
            sendDatalayerEvent({
              section: 'dobra_03',
              section_name: 'Por que abrir uma conta Inter Empresas?',
              element_action: 'click button',
              element_name: 'Abrir Conta MEI gratuita',
              redirect_url: url,
             })
          }}
        >
          Abrir Conta MEI gratuita
        </ButtonLink>
      </div>
    </SectionComponent>
  )
}

export default PorqueAbrirUmaConta
