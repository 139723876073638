import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bgHeroPlanilhaDreMobile: imageSharp(fluid: {originalName: { regex: "/bg-hero-planilha-dre-mobile/" }}) {
        fluid(maxWidth: 5, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      backgroundHeroPlanilhaDreTablet: imageSharp(fluid: {originalName: { regex: "/background-hero-planilha-dre-tablet/" }}) {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      backgroundHeroPlanilhaDreDesktopLG: imageSharp(fluid: {originalName: { regex: "/background-hero-planilha-dre-desktop-lg/" }}) {
        fluid(maxWidth: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      backgroundHeroPlanilhaDreDesktopXL: imageSharp(fluid: {originalName: { regex: "/background-hero-planilha-dre-desktop-xl/" }}) {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
