import React, { useState, useEffect, ChangeEvent } from 'react'
import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import { getParameterByName, sendCDPFormData } from 'src/shared/helpers'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import AcceptTerms from 'src/components/AcceptTerms/OpenAccount'
import useDomReady from 'src/hooks/window/useDomReady'

import { Modal } from 'src/components/Modal'
import SendFormModal from './sendFormModal'
import ErrorFormModal from './errorFormModal'

import { Container } from './style'

type FormPlanilhaProps = {
  closeModal?: () => void | false;
  dataLayer: IDataLayerParams;
}
interface IFormValues {
  nome: string;
  email: string;
  cpfCnpj: string;
  conteudo01: string;
}

function FormPlanilhaDRE ({ closeModal, dataLayer }: FormPlanilhaProps) {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isClient, setIsClient ] = useState(true)
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ isOpen, setIsOpen ] = useState(false)

  const openModalSent = domReady && sent && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <SendFormModal
        closeModal={() => setIsOpen(false)}
        dataLayer={dataLayer}
        setSent={setSent}
        setError={setError}
        setLoading={setLoading}
      />
    </Modal>
  )

  const openModalError = domReady && error && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <ErrorFormModal
        closeModal={() => setIsOpen(false)}
        dataLayer={dataLayer}
        setError={setError}
        setSent={setSent}
        setLoading={setLoading}
      />
    </Modal>
  )

  useEffect(() => {
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
  }, [])

  const sendForm = async (data: IFormValues) => {
    setLoading(true)
    const isClientFormatted = isClient ? 'Sim' : 'Nao'

    const formData = {
      ...data,
      campanha: 'Planilha DRE',
      nome: data.nome,
      cpfCnpj: data.cpfCnpj.replace(/\D/g, ''),
      email: data.email,
      conteudo01: isClientFormatted,
      conteudo02: utmCampaign?.toString(),
      conteudo03: utmMedium?.toString(),
      aceite: accept,
    }

    try {
      await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ])
      setLoading(false)
      setSent(true)
      sendCDPFormData({ formName: 'Formulario Planilha DRE', cpf: formData.cpfCnpj, email: formData.email })
      sendDatalayerEvent({
        section: `m_${dataLayer.section}`,
        element_action: 'submit',
        element_name: 'Continuar',
        element_previous: dataLayer.element_previous,
        section_name: dataLayer.section_name,
        step: 'success',
      })
    } catch (e) {
      setError(true)
      setLoading(false)
      sendDatalayerEvent({
        section: `m_${dataLayer.section}`,
        element_action: 'submit',
        element_name: 'Continuar',
        element_previous: dataLayer.element_previous,
        section_name: dataLayer.section_name,
      })
    }
  }

  return (
    <>
      {sent && SendFormModal}
      {error && ErrorFormModal}
      <Container isModal={closeModal} className='d-flex align-items-center'>
        {openModalError}
        {openModalSent}
        <div className='row'>
          <div className='col-12 text-md-center pt-4 mt-3 pt-md-0 mt-md-0'>
            <h2 className='font-sora fs-16 lh-20 fs-md-24 lh-md-30 mb-3 text-white'>
              Baixe sua planilha gratuitamente
            </h2>
            <p className='fs-12 lh-15 fs-md-16 lh-md-20 text-white'>
              Simplifique a gestão financeira do seu negócio.
            </p>
          </div>
          <div className='col-12'>
            <form className='form--default' name='open_account' onSubmit={handleSubmit(sendForm)}>
              <div className='body-form'>
                <div className='col-12 d-flex flex-column px-0 mb-3 pb-1'>
                  <label htmlFor='nome' className='fs-12 lh-15 fs-md-14 lh-md-17 fw-600'>Nome</label>
                  <input
                    ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                    name='nome'
                    id='nome'
                    type='text'
                    maxLength={100}
                    placeholder='Digite seu nome'
                  />
                  {errors.nome && <p className='fs-12 text-white mb-0 text-right'>{errors.nome.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 mb-3 pb-1'>
                  <label htmlFor='email' className='fs-12 lh-15 fs-md-14 lh-md-17 fw-600'>E-mail</label>
                  <input
                    ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    name='email'
                    id='email'
                    type='text'
                    placeholder='Digite seu e-mail'
                  />
                  {errors.email && <p className='fs-12 text-white mb-0 text-right'>{errors.email.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 mb-3 pb-1'>
                  <label htmlFor='cpfCnpj' className='fs-12 lh-15 fs-md-14 lh-md-17 fw-600'>CPF</label>
                  <input
                    ref={register({
                      required: 'Insira um CPF válido',
                      validate: {
                        isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                      },
                    })}
                    name='cpfCnpj'
                    id='cpfCnpj'
                    type='tel'
                    placeholder='Informe seu CPF'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpfCnpj', Masks.MaskCPF(event.currentTarget.value))}
                  />
                  {errors.cpfCnpj && <p className='fs-12 text-white mb-0 text-right'>{errors.cpfCnpj.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0'>
                  <label htmlFor='possuiCnpj' className='fs-14 lh-17 text-white mb-md-0'>Você possui um CNPJ ativo?</label>
                  <div className='d-flex justify-content-start pt-1 pt-md-0'>
                    <label className='radio-contato' htmlFor='possuiCnpj'>
                      <input
                        ref={register({
                        required: 'Informação obrigatória',
                      })}
                        type='radio'
                        id='possuiCnpj'
                        name='conteudo01'
                        value='sim'
                        onClick={() => setIsClient(true)}
                      />
                      <span className='radio-check' />
                      Sim
                    </label>
                    <label className='radio-contato' htmlFor='naoPossuiCnpj'>
                      <input
                        ref={register({
                          required: 'Informação obrigatória',
                        })}
                        type='radio'
                        id='naoPossuiCnpj'
                        name='conteudo01'
                        value='nao'
                        onClick={() => setIsClient(false)}
                      />
                      <span className='radio-check' />
                      Não
                    </label>
                  </div>
                  {errors.conteudo01 && <p className='fs-12 mt-1 mb-0 pt-1 text-white text-right'>{errors.conteudo01.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 mb-2'>
                  <AcceptTerms accept={accept} setAccept={setAccept} name='conta-digital-pf' dataLayer={dataLayer} />
                </div>
                <div className='col-12 text-center px-0'>
                  <button
                    type='submit' title='Enviar'
                    disabled={!accept || loading}
                    className='btn btn--lg bg-white fw-600 text-none mb-5'
                    onClick={() => setIsOpen(true)}
                  >
                    {loading ? 'Enviando...' : 'Baixar agora'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  )
}

export default FormPlanilhaDRE
